import { db, eventListeners } from '@/firebase/config'
import { ref } from "vue"
import { getProfile } from '../Profile/getProfile'
import toggleUnread from '../Msg/toggleUnread'

let unsubscribe = null;

const getPosition = (chatterIds, userId) => {
  for (let i = 0; i < chatterIds.length; i++) {
    if (chatterIds[i] == userId) {
      return i;
    }
  }
  throw Error("Cannot get position in a chat that I am not part of")
}

const removeListener = async () => {
  if (unsubscribe) {
    unsubscribe();
    eventListeners.splice(eventListeners.indexOf(unsubscribe), 1)
  }
}

const getContacts = async (store, finishedStore, userId, activeChat) => {
  try {
    store.value = [];
    const existingContactIds = [];
    if (unsubscribe) {
      unsubscribe();
      eventListeners.splice(eventListeners.indexOf(unsubscribe), 1)
    }
    unsubscribe = await db.collection('Messages').where("chatterIds", "array-contains", userId).onSnapshot(snapshot => {
      const changes = snapshot.docChanges();
      let promises = []
      changes.forEach(async change => {
        if (existingContactIds.includes(change.doc.id)) {
          store.value.forEach(contact => {
            if (contact.id == change.doc.id) {
              contact.unreadNotice = change.doc.data().unreadNotice;
              contact.unreadCount = change.doc.data().unreadCount;
              if (activeChat.value == contact.id) {
                contact.unreadNotice[contact.position] = false;
                contact.unreadCount = 0;
                toggleUnread(contact.id, contact.position, change.doc.data().unreadNotice);
              }
            }
          });
        } else {
          existingContactIds.push(change.doc.id);
          const data = change.doc.data();
          const pos = getPosition(data.chatterIds, userId);
          const otherPos = (pos + 1) % 2;
          const profile = ref(null);
          promises.push(getProfile(profile, data.chatterIds[otherPos]).then(() => {
            store.value.push({
              id: change.doc.id,
              chatterName: profile.value.firstName + " " + profile.value.lastName,
              chatterUid: data.chatterIds[otherPos],
              unreadNotice: data.unreadNotice,
              unreadCount: data.unreadCount,
              position: pos
            });
          }))
        }
      });
      return Promise.all(promises).then(() => {finishedStore.value = true});
    })
    eventListeners.push(unsubscribe)
  } catch (error) {
    alert("getContacts " + error);
  }
};

export default {getContacts, removeListener}
