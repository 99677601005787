import { db, eventListeners } from '@/firebase/config'

let unsubscribe = null;

const getChat = async (store, chatId) => {
  try {
    store.value = [];

    if (unsubscribe) {
      unsubscribe();
      eventListeners.splice(eventListeners.indexOf(unsubscribe), 1)
    }

    unsubscribe = await db.collection('Messages').doc(chatId).collection('history').orderBy('time').onSnapshot(snapshot => {
      const changes = snapshot.docChanges();
      changes.forEach(change => {
        store.value.push({ ...change.doc.data(), id: change.doc.id });
      });
    })
    eventListeners.push(unsubscribe)

    // console.log("getChat activated");
    // console.log(store.value)
  } catch (error) {
    alert("getChat " + error);
  }
};

export default getChat
