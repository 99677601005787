import { db } from '@/firebase/config'
import getUser from "@/composables/getUser";

const { user } = getUser();

const toggleUnread = async (chatId, position, unreadArr) => {
  try {
    unreadArr[position] = false;
    await db.collection('Messages').doc(chatId).get().then(async (doc)=>{
      const unreadCountArr = doc.data().unreadCount
      await db.collection("Profiles").doc(user.value.uid).collection("myUnreadMsgs").doc("unreadMsgs").get().then(async (document)=>{
        let newUnreadMsgs = document.data().unreadMsgs - unreadCountArr[position]
        if (newUnreadMsgs<0){
          newUnreadMsgs = 0
        }
        await document.ref.update({
          unreadMsgs: newUnreadMsgs
        })
      })
      unreadCountArr[position] = 0
      await doc.ref.update({
        unreadNotice: unreadArr,
        unreadCount: unreadCountArr,
      })
    })
  } catch (error) {
    alert("toggleUnread " + error);
  }
};

export default toggleUnread
